export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "words": {
          "pineapple": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["pineapple"])};fn.source="pineapple";return fn;})(),
          "strawberry": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["strawberry"])};fn.source="strawberry";return fn;})(),
          "blackcurrant": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["blackcurrant"])};fn.source="blackcurrant";return fn;})(),
          "grapefruit": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["grapefruit"])};fn.source="grapefruit";return fn;})(),
          "orange": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["orange"])};fn.source="orange";return fn;})(),
          "banana": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["banana"])};fn.source="banana";return fn;})(),
          "watermelon": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["watermelon"])};fn.source="watermelon";return fn;})(),
          "lemon": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["lemon"])};fn.source="lemon";return fn;})(),
          "kiwi": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["kiwi"])};fn.source="kiwi";return fn;})(),
          "peach": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["peach"])};fn.source="peach";return fn;})(),
          "cauliflower": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["cauliflower"])};fn.source="cauliflower";return fn;})(),
          "broccoli": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["broccoli"])};fn.source="broccoli";return fn;})(),
          "lettuce": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["lettuce"])};fn.source="lettuce";return fn;})(),
          "carrot": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["carrot"])};fn.source="carrot";return fn;})(),
          "radish": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["radish"])};fn.source="radish";return fn;})(),
          "courgette": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["courgette"])};fn.source="courgette";return fn;})(),
          "mushroom": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["mushroom"])};fn.source="mushroom";return fn;})(),
          "asparagus": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["asparagus"])};fn.source="asparagus";return fn;})(),
          "sprout": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["sprout"])};fn.source="sprout";return fn;})(),
          "pepper": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["pepper"])};fn.source="pepper";return fn;})()
        },
        "status": {
          "victory": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You win!"])};fn.source="You win!";return fn;})(),
          "failure": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You lose!"])};fn.source="You lose!";return fn;})()
        },
        "instructions": {
          "hangman": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Play Hangman while you wait."])};fn.source="Play Hangman while you wait.";return fn;})()
        },
        "button": {
          "tryAgain": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Try again"])};fn.source="Try again";return fn;})()
        }
      },
      "de": {
        "words": {
          "pineapple": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ananas"])};fn.source="Ananas";return fn;})(),
          "strawberry": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erdbeere"])};fn.source="Erdbeere";return fn;})(),
          "blackcurrant": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Johannisbeere"])};fn.source="Johannisbeere";return fn;})(),
          "grapefruit": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grapefruit"])};fn.source="Grapefruit";return fn;})(),
          "orange": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Orange"])};fn.source="Orange";return fn;})(),
          "banana": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Banane"])};fn.source="Banane";return fn;})(),
          "watermelon": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wassermelone"])};fn.source="Wassermelone";return fn;})(),
          "lemon": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zitrone"])};fn.source="Zitrone";return fn;})(),
          "kiwi": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kiwi"])};fn.source="Kiwi";return fn;})(),
          "peach": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pfirsich"])};fn.source="Pfirsich";return fn;})(),
          "cauliflower": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Blumenkohl"])};fn.source="Blumenkohl";return fn;})(),
          "broccoli": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Broccoli"])};fn.source="Broccoli";return fn;})(),
          "lettuce": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Salat"])};fn.source="Salat";return fn;})(),
          "carrot": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Karotte"])};fn.source="Karotte";return fn;})(),
          "radish": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Radieschen"])};fn.source="Radieschen";return fn;})(),
          "courgette": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zucchetti"])};fn.source="Zucchetti";return fn;})(),
          "mushroom": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Champignon"])};fn.source="Champignon";return fn;})(),
          "asparagus": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spargel"])};fn.source="Spargel";return fn;})(),
          "sprout": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rosenkohl"])};fn.source="Rosenkohl";return fn;})(),
          "pepper": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Peperoni"])};fn.source="Peperoni";return fn;})()
        },
        "status": {
          "victory": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Du hast gewonnen!"])};fn.source="Du hast gewonnen!";return fn;})(),
          "failure": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Du hast verloren!"])};fn.source="Du hast verloren!";return fn;})()
        },
        "instructions": {
          "hangman": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spiel Galgenraten während du wartest."])};fn.source="Spiel Galgenraten während du wartest.";return fn;})()
        },
        "button": {
          "tryAgain": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erneut versuchen"])};fn.source="Erneut versuchen";return fn;})()
        }
      }
    }
  })
}
