export default {
  "name": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Management portal"])};fn.source="Management portal";return fn;})(),
  "button": {
    "back": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Back"])};fn.source="Back";return fn;})(),
    "create": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create"])};fn.source="Create";return fn;})(),
    "edit": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit"])};fn.source="Edit";return fn;})(),
    "delete": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete"])};fn.source="Delete";return fn;})(),
    "cancel": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel"])};fn.source="Cancel";return fn;})(),
    "save": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save"])};fn.source="Save";return fn;})(),
    "saveCreate": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create"])};fn.source="Create";return fn;})(),
    "saveEdit": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save"])};fn.source="Save";return fn;})(),
    "ok": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["OK"])};fn.source="OK";return fn;})(),
    "done": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Done"])};fn.source="Done";return fn;})(),
    "next": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Next"])};fn.source="Next";return fn;})(),
    "import": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Import"])};fn.source="Import";return fn;})()
  }
}