export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "title": {
          "somethingWentWrong": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Something went wrong"])};fn.source="Something went wrong";return fn;})()
        },
        "instructions": {
          "callHelpdesk": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact Helpdesk at"])};fn.source="Contact Helpdesk at";return fn;})(),
          "or": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["or"])};fn.source="or";return fn;})()
        }
      },
      "de": {
        "title": {
          "somethingWentWrong": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Etwas ist schiefgelaufen"])};fn.source="Etwas ist schiefgelaufen";return fn;})()
        },
        "instructions": {
          "callHelpdesk": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kontaktiere den Helpdesk unter"])};fn.source="Kontaktiere den Helpdesk unter";return fn;})(),
          "or": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["oder"])};fn.source="oder";return fn;})()
        }
      }
    }
  })
}
