<template>
  <div class="hangman-game">
    <h3>{{ t('instructions.hangman') }}</h3>
    <div class="guess-word">
      <span v-for="(letter, index) in selectedWord" :key="index">
        {{ guessedLetters.includes(letter) ? `${letter}` : '_ ' }}
      </span>
    </div>
    <div class="hangman-image">
      <img :src="stickFigure" alt="Hangman">
    </div>
    <div class="alphabet">
      <button
          v-for="(letter, index) in alphabet"
          :key="index"
          :disabled="guessedLetters.includes(letter) || gameStatus !== 'playing'"
          @click="guessLetter(letter)">
        {{ letter }}
      </button>
    </div>
    <div class="game-status" v-if="gameStatus !== 'playing'">
      <h2>{{ gameStatus }}</h2>
      <b-button @click="restartGame">{{ t('button.tryAgain') }}</b-button>
    </div>
  </div>
</template>

<script>
import { useI18n } from 'vue-i18n';

const hangmanGame = {
  name: 'HangmanGame',
  setup() {
    const { t } = useI18n({
      inheritLocale: true,
      useScope: 'local',
    });
    return { t };
  },
  data() {
    return {
      wordLabels: [
        'pineapple',
        'strawberry',
        'blackcurrant',
        'grapefruit',
        'orange',
        'banana',
        'watermelon',
        'lemon',
        'kiwi',
        'peach',
        'cauliflower',
        'broccoli',
        'lettuce',
        'carrot',
        'radish',
        'courgette',
        'mushroom',
        'asparagus',
        'sprout',
        'pepper',
      ],
      selectedWord: '',
      guessedLetters: [],
      maxWrongGuesses: 6,
      wrongGuesses: 0,
      gameStatus: 'playing',
      alphabet: 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'.split(''),
    };
  },
  computed: {
    words() {
      return this.wordLabels.map((word) => this.t(`words.${word}`).toUpperCase());
    },
    stickFigure() {
      return `${process.env.BASE_URL}stickfigure/hangman-${this.wrongGuesses}.png`;
    },
  },
  mounted() {
    this.selectRandomWord();
  },
  methods: {
    selectRandomWord() {
      this.selectedWord = this.words[Math.floor(Math.random() * this.words.length)];
    },
    guessLetter(letter) {
      if (!this.selectedWord.includes(letter)) {
        // eslint-disable-next-line no-plusplus
        this.wrongGuesses++;
      }
      this.guessedLetters.push(letter);
      if (this.checkWin()) {
        this.gameStatus = this.t('status.victory');
      } else if (this.checkLose()) {
        this.gameStatus = this.t('status.failure');
      }
    },
    checkWin() {
      return this.selectedWord.split('').every((letter) => this.guessedLetters.includes(letter));
    },
    checkLose() {
      return this.wrongGuesses >= this.maxWrongGuesses;
    },
    restartGame() {
      this.selectedWord = '';
      this.guessedLetters = [];
      this.wrongGuesses = 0;
      this.gameStatus = 'playing';
      this.selectRandomWord();
    },
  },
};

export default hangmanGame;

</script>

<style scoped lang="scss">
.guess-word {
  font-size: 2rem;
  margin-bottom: 2rem;
}
.hangman-image {
  margin-bottom: 2rem;
}
.alphabet {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 2rem;
}
.alphabet button {
  margin: 0.5rem;
  padding: 0.5rem;
  width: 2rem;
  font-size: 1.2rem;
  border: none;
  border-radius: 0.5rem;
  background-color: #ccc;
  cursor: pointer;
}
.alphabet button:disabled {
  background-color: #999;
  cursor: not-allowed;
}
.game-status {
  text-align: center;
}
</style>
<i18n>
{
  "en": {
    "words": {
      "pineapple": "pineapple",
      "strawberry": "strawberry",
      "blackcurrant": "blackcurrant",
      "grapefruit": "grapefruit",
      "orange": "orange",
      "banana": "banana",
      "watermelon": "watermelon",
      "lemon": "lemon",
      "kiwi": "kiwi",
      "peach": "peach",
      "cauliflower": "cauliflower",
      "broccoli": "broccoli",
      "lettuce": "lettuce",
      "carrot": "carrot",
      "radish": "radish",
      "courgette": "courgette",
      "mushroom": "mushroom",
      "asparagus": "asparagus",
      "sprout": "sprout",
      "pepper": "pepper"
    },
    "status": {
      "victory": "You win!",
      "failure": "You lose!"
    },
    "instructions": {
      "hangman": "Play Hangman while you wait."
    },
    "button": {
      "tryAgain": "Try again"
    }
  },
  "de": {
    "words": {
      "pineapple": "Ananas",
      "strawberry": "Erdbeere",
      "blackcurrant": "Johannisbeere",
      "grapefruit": "Grapefruit",
      "orange": "Orange",
      "banana": "Banane",
      "watermelon": "Wassermelone",
      "lemon": "Zitrone",
      "kiwi": "Kiwi",
      "peach": "Pfirsich",
      "cauliflower": "Blumenkohl",
      "broccoli": "Broccoli",
      "lettuce": "Salat",
      "carrot": "Karotte",
      "radish": "Radieschen",
      "courgette": "Zucchetti",
      "mushroom": "Champignon",
      "asparagus": "Spargel",
      "sprout": "Rosenkohl",
      "pepper": "Peperoni"
    },
    "status": {
      "victory": "Du hast gewonnen!",
      "failure": "Du hast verloren!"
    },
    "instructions": {
      "hangman": "Spiel Galgenraten während du wartest."
    },
    "button": {
      "tryAgain": "Erneut versuchen"
    }
  }
}
</i18n>
