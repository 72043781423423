<template>
  <div id="app">
    <div class="error-page">
      <h1>{{ t('title.somethingWentWrong') }}</h1>
      <p>{{ t('instructions.callHelpdesk') }} {{ helpdeskPhone }} {{ t('instructions.or') }}
        <a :href="`mailto:${helpdeskMail}`">{{ helpdeskMail }}</a></p>
      <hangman-game v-if="showHangman"/>
    </div>
  </div>
</template>

<script>
import { useI18n } from 'vue-i18n';
import hangmanGame from '@/components/HangmanGame.vue';

const error = {
  name: 'Error',
  components: {
    hangmanGame,
  },
  setup() {
    const { t } = useI18n({
      inheritLocale: true,
      useScope: 'local',
    });
    return { t };
  },
  data() {
    return {
      helpdeskPhone: '+41 41 500 50 49',
      helpdeskMail: 'helpdesk@innetag.ch',
    };
  },
  computed: {
    showHangman() {
      return process.env.VUE_APP_HANGMAN_GAME === 'true';
    },
  },
};
export default error;
</script>

<style scoped>
.error-page {
  margin: 2rem;
}
</style>
<i18n>
{
  "en": {
    "title": {
      "somethingWentWrong": "Something went wrong"
    },
    "instructions": {
      "callHelpdesk": "Contact Helpdesk at",
      "or": "or"
    }
  },
  "de": {
    "title": {
      "somethingWentWrong": "Etwas ist schiefgelaufen"
    },
    "instructions": {
      "callHelpdesk": "Kontaktiere den Helpdesk unter",
      "or": "oder"
    }
  }
}
</i18n>
