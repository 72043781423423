// eslint-disable-next-line max-classes-per-file
const jose = require('jose');

class PrivateACL {
  constructor() {
    this.algorithm = process.env.VUE_APP_KEYCLOAK_ENC_ALGORITHM;
    this.key = `-----BEGIN PUBLIC KEY-----
    ${process.env.VUE_APP_KEYCLOAK_PUBLIC_KEY}
    -----END PUBLIC KEY-----`;
    this.roles = {};
  }

  async setToken(token) {
    this.token = token;
    await this.#decodeToken();
    this.#generateACL();
  }

  async #decodeToken() {
    const key = await jose.importSPKI(this.key, this.algorithm);
    const { payload } = await jose.jwtVerify(this.token, key, { issuer: `${process.env.VUE_APP_KEYCLOAK_URL}/realms/${process.env.VUE_APP_KEYCLOAK_REALM}` });
    this.jwtPayload = payload;
  }

  #generateACL() {
    if (!this.jwtPayload) return;
    const regexpAuth = /([A-Za-z]+):([A-Za-z]+)/mg;
    this.jwtPayload.resource_access[process.env.VUE_APP_KEYCLOAK_CLIENTID].roles.forEach((item) => {
      const entity = item.replace(regexpAuth, '$1');
      const permission = item.replace(regexpAuth, '$2');
      if (!Object.keys(this.roles).includes(entity)) {
        this.roles[entity] = [permission];
        return;
      }
      this.roles[entity].push(permission);
    });
  }

  getUserName() {
    return this.jwtPayload?.preferred_username;
  }

  #hasAccess(item, action) {
    if (!Object.keys(this.roles).includes(item)) return false;
    return this.roles[item].includes(action);
  }

  hasAdministrationPageAccess() {
    return this.#hasAccess('administration', 'write');
  }

  hasWarningPageAccess() {
    return this.#hasAccess('warning', 'write');
  }

  hasWriteAccessOnSites() {
    return this.#hasAccess('sites', 'write');
  }

  hasReadAccessOnSites() {
    return this.#hasAccess('sites', 'read');
  }

  hasWriteAccessOnLogger() {
    return this.#hasAccess('logger', 'write');
  }

  hasReadAccessOnLoggers() {
    return this.#hasAccess('logger', 'read');
  }

  hasWriteAccessOnWarnings() {
    return this.#hasAccess('warning', 'write');
  }

  hasWriteAccessOnDatamodification() {
    return this.#hasAccess('datamodification', 'write');
  }

  hasWriteAccessOnDatacontrol() {
    return this.#hasAccess('datacontrol', 'write');
  }

  hasWriteAccessOnGroup() {
    return this.#hasAccess('group', 'write');
  }
}

class ACL {
  constructor() {
    throw new Error('Use ACL.getInstance()');
  }

  static getInstance() {
    if (!ACL.instance) {
      ACL.instance = new PrivateACL();
    }
    return ACL.instance;
  }
}

module.exports = ACL;
