import { createRouter, createWebHistory } from 'vue-router';
import ACL from '@/helpers/acl';

const routes = [
  { path: '/', redirect: '/sites' },
  {
    path: '/sites/:modal?/:code?',
    name: 'Sites',
    component: () => import(/* webpackChunkName: "sites" */ '../views/BrowseSites.vue'),
  },
  {
    path: '/loggers/:modal?/:code?',
    name: 'Loggers',
    component: () => import(/* webpackChunkName: "loggers" */ '../views/BrowseLoggers.vue'),
  },
  {
    path: '/admin',
    name: 'Administration',
    component: () => import(/* webpackChunkName: "admin" */ '../views/Administration.vue'),
    beforeEnter: () => ACL.getInstance().hasWriteAccessOnGroup(),
  },
  {
    path: '/warning',
    name: 'Warnings',
    component: () => import(/* webpackChunkName: "warnings" */ '../views/BrowseWarnings.vue'),
    beforeEnter: () => ACL.getInstance().hasWarningPageAccess(),
  },
  {
    path: '/dataControl',
    name: 'DataControl',
    component: () => import(/* webpackChunkName: "warnings" */ '../views/DataControl.vue'),
    beforeEnter: () => ACL.getInstance().hasWriteAccessOnDatacontrol(),
  },
  {
    path: '/dataCorrection',
    name: 'DataCorrection',
    component: () => import(/* webpackChunkName: "correction" */ '../views/DataCorrection.vue'),
    beforeEnter: () => ACL.getInstance().hasWriteAccessOnDatamodification(),
  },
  {
    path: '/dataImport',
    name: 'Data Import',
    component: () => import(/* webpackChunkName: "import" */ '../views/DataImport.vue'),
    beforeEnter: () => ACL.getInstance().hasWriteAccessOnDatamodification(),
  },
  {
    path: '/siteAdmin/:mode/:siteId?/:modal?/:code?',
    name: 'SiteAdministration',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "site-admin" */ '../views/SiteAdministration.vue'),
  },
  {
    path: '/loggerAdmin/:mode/:loggerId?/:modal?/:code?',
    name: 'LoggerAdministration',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "logger-admin" */ '../views/LoggerAdministration.vue'),
  },
  {
    path: '/warningAdmin/:mode/:timeSeriesId/:warningId?/:modal?/:code?',
    name: 'WarningAdministration',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "warning-admin" */ '../views/WarningAdministration.vue'),
  },
  {
    path: '/impressum',
    name: 'Impressum',
    component: () => import(/* webpackChunkName: "impressum" */ '../views/ImpressumPage.vue'),
  },
  {
    path: '/helperVue',
    name: 'HelperPageVue',
    component: () => import(/* webpackChunkName: "helper" */ '../views/HelperPage.vue'),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
