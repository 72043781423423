export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "label": {
          "admin": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Administration"])};fn.source="Administration";return fn;})(),
          "warning": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Warning"])};fn.source="Warning";return fn;})(),
          "dataControl": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data Control"])};fn.source="Data Control";return fn;})(),
          "dataCorrection": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data Correction"])};fn.source="Data Correction";return fn;})(),
          "dataImport": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data Import"])};fn.source="Data Import";return fn;})(),
          "sites": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sites"])};fn.source="Sites";return fn;})(),
          "loggers": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Loggers"])};fn.source="Loggers";return fn;})(),
          "helperPage": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Help"])};fn.source="Help";return fn;})(),
          "datamodification": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datamodification"])};fn.source="Datamodification";return fn;})()
        }
      },
      "de": {
        "label": {
          "admin": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Administration"])};fn.source="Administration";return fn;})(),
          "warning": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Warnungen"])};fn.source="Warnungen";return fn;})(),
          "dataControl": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datenkontrolle"])};fn.source="Datenkontrolle";return fn;})(),
          "dataCorrection": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datenkorrektur"])};fn.source="Datenkorrektur";return fn;})(),
          "dataImport": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datenimport"])};fn.source="Datenimport";return fn;})(),
          "sites": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Standorte"])};fn.source="Standorte";return fn;})(),
          "loggers": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Logger"])};fn.source="Logger";return fn;})(),
          "helperPage": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hilfe"])};fn.source="Hilfe";return fn;})(),
          "datamodification": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datenmodifikation"])};fn.source="Datenmodifikation";return fn;})()
        }
      }
    }
  })
}
