export default {
  "name": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Management Portal"])};fn.source="Management Portal";return fn;})(),
  "button": {
    "back": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zurück"])};fn.source="Zurück";return fn;})(),
    "create": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erstellen"])};fn.source="Erstellen";return fn;})(),
    "edit": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bearbeiten"])};fn.source="Bearbeiten";return fn;})(),
    "delete": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Löschen"])};fn.source="Löschen";return fn;})(),
    "cancel": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abbrechen"])};fn.source="Abbrechen";return fn;})(),
    "save": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Speichern"])};fn.source="Speichern";return fn;})(),
    "saveCreate": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hinzufügen"])};fn.source="Hinzufügen";return fn;})(),
    "saveEdit": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Speichern"])};fn.source="Speichern";return fn;})(),
    "ok": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ok"])};fn.source="Ok";return fn;})(),
    "done": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fertig"])};fn.source="Fertig";return fn;})(),
    "next": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weiter"])};fn.source="Weiter";return fn;})(),
    "import": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Importieren"])};fn.source="Importieren";return fn;})()
  }
}